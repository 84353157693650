<template>
  <div class="margin-config fill-height text-center" v-if="!isLoading">
    <v-row justify="center" align="center">
      <v-col cols="12">
        <span class="my-folders-text">Configuraciones / </span>
        <span>Tipos de plantilla</span>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="8">
        <v-text-field
          v-model="tempTypeListSearch"
          label="Buscar..."
        >
        </v-text-field>
      </v-col>
      <v-col style="align-self: center; text-align: right;" cols="4">
        <v-btn
          depressed
          color="default-btn primary"
          @click="createTempTypeDialog = true, isCreateOrUpdate = true"
        >
          Agregar tipo de plantilla
        </v-btn>
      </v-col>
      <v-col cols="12">
        <span class="my-folders-text">
          <v-icon color="secondary">
            mdi-exclamation-thick
          </v-icon>
          La edición de los nombres de las categorías de plantillas deben ser similares
          al actual ya que esto se verá reflejado en todas las plantillas que se hayan creado con dicha opción.
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <h5>Nombre</h5>
      </v-col>
      <v-col cols="3">
        <h5>Usuario mod</h5>
      </v-col>
      <v-col cols="3">
        <h5>Actualizado</h5>
      </v-col>
      <v-col cols="3">
        <h5>Acción</h5>
      </v-col>
    </v-row>
    <v-data-iterator
      :items="tempTypeList"
      :search="tempTypeListSearch"
      loading-text="Cargando"
      :loading="tempTypeListLoader"
      no-data-text="No hay tipos de plantilla creados"
      :items-per-page="8"
      :footer-props="{itemsPerPageOptions:[8, 16, -1]}"
    >
      <template v-slot:default="props">
          <div v-for="(tempTypeItem, index) in props.items" :key="index">
          <v-row :class="index%2 === 0 ? 'backWhite' : 'backGray'">
            <v-col cols="3">
              <span> {{ tempTypeItem.name }} </span>
            </v-col>
            <v-col cols="3">
              <span> {{ tempTypeItem.usermod }} </span>
            </v-col>
            <v-col cols="3">
              <span> {{ tempTypeItem.updated }} </span>
            </v-col>
            <v-col cols="3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="editTempType(tempTypeItem)"
                  >
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                    >mdi-pencil-outline</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-col>
          </v-row>
          </div>
      </template>
  </v-data-iterator>
    <v-dialog
      max-width='500px'
      v-model="createTempTypeDialog"
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
    >
      <v-card class="pa-4" max-width="auto" height="auto">
        <v-card-title class="center-dialog centered-dialog__card">
          <v-icon class="mx-1">mdi-file-hidden</v-icon>
          <h4>{{ getCreateOrEdit() }} Tipo de plantilla</h4>
          <v-icon class="mx-1">mdi-file-hidden</v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="tempTypeForm" v-model="tempTypeForm.isValid">
            <v-row no-gutters>
              <v-col class="px-8 py-0" cols="12">
                <label class="form-label" for="name">
                  Nombre
                </label>
                <v-text-field
                  class="form-field mt-2"
                  :rules="textFieldRules"
                  name="name"
                  outlined
                  single-line
                  background-color="white"
                  v-model="tempTypeForm.name"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters justify="center">
            <v-col cols="auto" class="pt-6 mr-3">
              <v-btn
                depressed
                color="default-btn accent"
                @click="closeDialog()"
              >
                Cerrar
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pt-6 ml-3">
              <v-btn
                depressed
                color="default-btn primary"
                :disabled="!tempTypeForm.isValid"
                :loading="createOrEditBtnLoader"
                @click="createOrUpdateTempType()"
              >
                {{ getCreateOrEdit() }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div v-else class="loading-container">
    <Loader />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { colors } from '@/utils/colors'

/* Components */
import Loader from '@/components/Base/Loader'

export default {
  name: 'Countries',
  components: {
    Loader
  },
  mixins: [],

  data () {
    return {
      isLoading: false,
      tempTypeList: [],
      tempTypeListSearch: '',
      tempTypeListLoader: false,
      createTempTypeDialog: false,
      countryCodeDisabled: false,
      tempTypeForm: {
        name: '',
        id: '',
        isValid: ''
      },
      textFieldRules: [
        v => !!v || 'Este campo es requerido'
      ],
      isCreateOrUpdate: false, // false for edit and true for create,
      createOrEditBtnLoader: false
    }
  },

  created () {
    this.tempTypeListLoader = true
    this.getTempTypes()
  },

  computed: {
    // ...mapState('contract', ['contracts', 'topFolders']),
  },

  methods: {
    ...mapActions('administration', ['getAdminTemplateTypes', 'addAdminTemplateType', 'updateAdminTemplateType']),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),

    getTempTypes () {
      this.getAdminTemplateTypes()
        .then(response => {
          this.tempTypeListLoader = false
          this.tempTypeList = response.data
          this.closeDialog()
        })
        .catch(() => {
          this.tempTypeListLoader = false
        })
    },

    editTempType (item) {
      this.isCreateOrUpdate = false
      this.countryCodeDisabled = true
      this.tempTypeForm.name = item.name
      this.tempTypeForm.countryCode = item.countryCode
      this.tempTypeForm.id = item.id
      this.createTempTypeDialog = true
    },

    createOrUpdateTempType () {
      this.createOrEditBtnLoader = true
      if (this.isCreateOrUpdate) {
        const request = {
          name: this.tempTypeForm.name
        }
        this.addAdminTemplateType(request)
          .then(() => {
            this.createOrEditBtnLoader = false
            this.getTempTypes()
            this.setSnackbar({
              text: 'Tipo de plantilla registrada correctamente',
              timeout: 5000,
              showing: true,
              color: colors.success
            })
          })
          .catch(() => {
            this.createOrEditBtnLoader = false
            this.setSnackbar({
              text: 'No se pudo crear el tipo de plantilla',
              timeout: 5000,
              showing: true,
              color: colors.error
            })
          })
      } else {
        const request = {
          name: this.tempTypeForm.name,
          id: this.tempTypeForm.id
        }
        this.updateAdminTemplateType(request)
          .then(() => {
            this.createOrEditBtnLoader = false
            this.getTempTypes()
            this.setSnackbar({
              text: 'Tipo de plantilla actualizada correctamente',
              timeout: 5000,
              showing: true,
              color: colors.success
            })
          })
      }
    },

    getCreateOrEdit () {
      return this.isCreateOrUpdate ? 'Crear' : 'Editar'
    },

    closeDialog () {
      this.createTempTypeDialog = false
      this.countryCodeDisabled = false
      this.$refs.tempTypeForm.resetValidation()
      this.tempTypeForm = {
        name: '',
        countryCode: '',
        isValid: false
      }
    }
  }
}
</script>

<style lang="scss">
.margin-config{
  padding-left: 10%;
  padding-right: 5%;
}

.my-folders-text{
  color: gray;
}

.center-dialog{
  text-align: center;
  justify-content: center;
}

</style>
